import { ShowBenefits } from "interfaces/benefitInterface"
import { SymplaEvent } from "interfaces/symplaEvent"
import { DisplayTokens } from "interfaces/tokenInterface"
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react"
import { cookies } from "utility/cookies"

import {
  CreatingCommunity,
  CreationVisibility,
  SecondFormCreateCommunityInputs,
  ShowCollections
} from "../../views/my-collections/collection-types"
import { ProductOfferStatus, DeleteModalInfos, ProductUtilityTokenModal } from "interfaces/productInterface"

export interface IContext {
  userData: UserData
  setUserData: any
  iscreatedRoyalties: boolean
  setIscreatedRoyalties: (string: boolean) => void
  isCreatedBenefit: boolean
  setIsCreatedBenefit: (string: boolean) => void
  showBenefits: ShowBenefits
  setShowBenefits: (string: ShowBenefits) => void
  productOfferStatus: ProductOfferStatus,
  deleteModal: DeleteModalInfos,
  setDeleteModal: Dispatch<SetStateAction<DeleteModalInfos>>,
  productUtilityTokens: ProductUtilityTokenModal,
  setProductUtilityTokens: Dispatch<SetStateAction<ProductUtilityTokenModal>>,
  setProductOfferStatus: (string: ProductOfferStatus) => void
  showCollections: ShowCollections
  setShowCollections: (string: ShowCollections) => void
  showTokens: DisplayTokens
  setShowTokens: (string: DisplayTokens) => void
  showCommunities: "display" | "to-manage" | "edit" | "create"
  setShowCommunities: (string: "display" | "to-manage" | "edit" | "create") => void
  setEventsView: (string: "initial" | "tokenize") => void
  eventsView: "initial" | "tokenize"
  collectionCreationVisibility: CreationVisibility
  setCollectionCreationVisibility: (string: CreationVisibility) => void
  creatingCommunity: CreatingCommunity
  setCreatingCommunity(string)
  secondFormaData: SecondFormCreateCommunityInputs
  setSecondFormData: (string: SecondFormCreateCommunityInputs) => void
  communityData: any
  setCommunityData: (string: any) => void
  eventToTokenize: SymplaEvent | undefined
  setEventToTokenize: any
  integrationIdToTokenize: string
  setIntegrationIdToTokenize: any
  tokenType: CreationVisibility
  setTokenType: (string: CreationVisibility) => void
}
export interface UserData {
  accessToken: string
  email: string
  id: string
  role: string
  status: string
  communities: Community[]
  secsToExpire: number
}
export interface Community {
  communityId: string
  role: string
}
const Context = createContext<IContext>(null)

export const ContextProvider = ({ children }) => {
  const [iscreatedRoyalties, setIscreatedRoyalties] = useState(false)
  const [isCreatedBenefit, setIsCreatedBenefit] = useState(false)

  const [showBenefits, setShowBenefits] = useState<"Home" | "Creating" | "Editing">("Home")
  const [productOfferStatus, setProductOfferStatus] = useState<ProductOfferStatus>("Home")
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    productInfos: { name: "", displayImageUrl: "", productId: "" }
  })

  const [productUtilityTokens, setProductUtilityTokens] = useState({
    isOpen: false,
    utilityTokens: []
  })
  const [showCollections, setShowCollections] = useState<ShowCollections>("display")
  const [showCommunities, setShowCommunities] = useState<"display" | "to-manage" | "edit" | "create">("display")
  const [showTokens, setShowTokens] = useState<DisplayTokens>("display")
  const [collectionCreationVisibility, setCollectionCreationVisibility] = useState<CreationVisibility>("typeSelection")
  const [creatingCommunity, setCreatingCommunity] = useState<CreatingCommunity>("config")
  const [communityData, setCommunityData] = useState({})
  const [eventsView, setEventsView] = useState<"initial" | "tokenize">("initial")
  const [eventToTokenize, setEventToTokenize] = useState<SymplaEvent | undefined>()
  const [integrationIdToTokenize, setIntegrationIdToTokenize] = useState<string | undefined>()
  const [tokenType, setTokenType] = useState<CreationVisibility | undefined>("typeSelection")

  const [secondFormaData, setSecondFormData] = useState<SecondFormCreateCommunityInputs>({
    pageTitle: "",
    link: "",
    iconUrl: "",
    descriptionTheme: "",
    logoHeaderUrl: "",
    colorBackgroundLayout: "",
    colorText: "",
    textColorHouver: "",
    colorButton: "",
    bodyBackgroundColor: "",
    bodyBackgroundImage: "",
    bodyTextColor: "",
    bodyTextHouverColor: "",
    bodyHighlightColor: "",
    bodyButtonColor: "",
    bodyButtonTextColor: "",
    bodyLinkColor: "",
    footerLink: "",
    footerbackgroundColor: "",
    footerTextColor: "",
    footerTextHouverColor: "",
    loadingColor: "",
    loadingBackgroundColor: ""
  })

  const data = cookies.get("userData")?.data

  const [userData, setUserData] = useState(data || {})

  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        iscreatedRoyalties,
        setIscreatedRoyalties,
        isCreatedBenefit,
        setIsCreatedBenefit,
        showBenefits,
        setShowBenefits,
        productOfferStatus,
        setProductOfferStatus,
        showCollections,
        setShowCollections,
        showTokens,
        setShowTokens,
        showCommunities,
        setShowCommunities,
        collectionCreationVisibility,
        setCollectionCreationVisibility,
        creatingCommunity,
        setCreatingCommunity,
        secondFormaData,
        setSecondFormData,
        communityData,
        setCommunityData,
        eventsView,
        setEventsView,
        setEventToTokenize,
        eventToTokenize,
        integrationIdToTokenize,
        setIntegrationIdToTokenize,
        deleteModal,
        setDeleteModal,
        productUtilityTokens,
        setProductUtilityTokens,
        tokenType,
        setTokenType
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useGlobalContext must be used within a StoreProvider")
  }
  return context
}
